import { useAxios } from '../../axiosUtil'
import { getLabPatent } from '../../platform/community'

const baseURL = '/v1/board/lab-patent' // 지재권 저장 url

const headerOpt = (method) => ({
  method
})

const patentApi = async (method, data, id) => {
  const opt = headerOpt(method)
  let url = baseURL

  if (data && typeof data === 'object') {
    opt.data = data
  }

  if (data && typeof data !== 'object') {
    url += `/${data}`
  }

  if (id || id === 0) {
    url += `/${id}`
  }

  const result = await useAxios(url, opt)

  return result
}

export const patentInsert = async (data) => {
  const result = await patentApi('POST', data)

  if (result.code === 'ER_DUP_ENTRY' && result.message.includes('board_lab_patent.patentResultID_UNIQUE')) {
    const patent = await getLabPatent({ patentResultID: data.patentResultID, deletedFlag: 1 })

    if (patent.items instanceof Array) {
      return patentApi('PUT', { ...data, deletedFlag: '0' }, patent.items[0].patentId)
    }
  }

  return result
}
export const patentUpdate = (data, id) => {
  return patentApi('PUT', data, id)
}
export const patentDelete = (id) => {
  return patentApi('DELETE', id)
}
