// INFO: 프로젝트 게시판 리스트 // TODO: 연구실 관리 계정으로 페이지에 들어오면 해당 연구실의 Data만 보여주기 // TODO: 프로젝트 상세 검색
<script>
import { GridComponent, ColumnsDirective, Page, Sort, Toolbar, Search, CommandColumn } from '@syncfusion/ej2-vue-grids'
import { getLabPjt } from '@/api/platform/community' // 프로젝트 조회
import { deletePjtTable } from '@/api/admin/ntis/ntisDelete' // 프로젝트 삭제
import AddBtn from '@/components/ui/AddBtn.vue' // 프로젝트 등록

import LoadingVue from '@/components/ui/Loading.vue'
// INFO: 검색 기능
import Dropdown from '@/components/ui/Dropdown.vue'

export default {
  name: 'adminBoardProjectList',
  components: {
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    AddBtn,
    LoadingVue,
    // INFO: 검색 기능
    Dropdown
  },
  data() {
    const labNo = Number(this.$route.params.labNo)

    return {
      currentPath: this.$route.matched[0].path, // 현재 접속 경로
      currentPage: '', // 현재 페이지 번호
      pjtGrid: '', // 프로젝트 그리드
      labNo, // 연구실 번호

      // ********** 그리드 세팅 **********
      pageSettings: { pageSize: 10 }, // 10개씩 잘라서 페이지네이션
      toolbar: ['Search'], // 툴바에 검색 옵션
      // formatoptions: { type: 'dateTime', format: 'y/M/d' }, // 컬럼 날짜 포맷. 시간까지 보이게 하려면 format: 'M/d/y hh:mm a'
      commands: [{ buttonOption: { content: '삭제', cssClass: 'e-danger' } }], // 삭제 버튼

      // ********** 프로젝트 데이터 **********
      projectData: [],
      projectStatus: '',
      // INFO: 검색 기능
      persistedData: [],
      searchConditions: [
        { value: 'all', text: '전체' },
        { value: 'labNo', text: '연구실번호' },
        { value: 'labName', text: '연구실이름' },
        { value: 'projectNumber', text: '과제고유번호 ' },
        { value: 'projectManager', text: '책임연구자' },
        { value: 'projectTitleKr', text: '과제명' },
        { value: 'projectStatus', text: '진행상태' }
      ],
      searchBy: ''
    }
  },
  provide: {
    grid: [Page, Sort, Toolbar, Search, CommandColumn]
  },

  watch: {
    async $route() {
      await this.loadPrjData()
    }
  },

  methods: {
    // ********** 프로젝트 데이터 가져오기 **********
    async loadPrjData() {
      const user = this.$store.state.userStore.info

      const data = user.groupName === 'admin' ? await getLabPjt() : await getLabPjt({ labNo: user.labNo })

      // 연구소 번호가 0이 아닐 때 연구소 번호에 맞는 과제만 보여주기
      const data2 = this.labNo !== 0 ? await getLabPjt({ labNo: this.labNo }) : ''

      if (this.labNo !== 0) {
        this.projectData = data2.items
      } else {
        console.log('no lab')
        this.projectData = data.items
      }
    },

    // ********** 한 행 클릭 시 보기로 이동 **********
    projectRowSelected(e) {
      // 그리드 현재 페이지
      this.currentPage = this.pjtGrid.pagerModule.pageSettings.currentPage

      // 한 행 클릭한 타켓이 버튼일 경우 리턴
      if (e.target.matches('button')) return

      // 연구소 번호가 0이 아닐 때 연구소 번호 보내주기
      if (this.labNo !== 0) {
        this.$router.push(`/admin/board/project/w/${e.data.projectId}/${this.currentPage}/${e.data.labNo}`)
      } else {
        this.$router.push(`/admin/board/project/w/${e.data.projectId}/${this.currentPage}/0`)
      }
    },
    // ********** 삭제 버튼 **********
    commandClick(e) {
      const target = e.target
      // 타겟이 삭제 버튼일 경우 삭제 동작 함수 호출
      if (target.matches('button.e-danger')) return this.delProject(e)
    },

    // ********** 삭제 동작 **********
    async delProject(e) {
      if (!confirm('선택한 과제를 정말로 삭제하시겠습니까?')) return

      const boardNo = e.rowData.projectId
      const result = await deletePjtTable(boardNo, e.rowData)
      if (result.code) return alert(result.message)
      alert('삭제되었습니다.')
      this.projectData = this.projectData.filter((item) => item.projectId !== boardNo)
    },

    // ********** 등록 버튼 **********
    goProjectCreate() {
      this.$router.push(`${this.currentPath}/w`)
    },

    // ********** 전체보기 버튼 **********
    viewAllData() {},

    // INFO: 검색 기능
    actionComplete(e) {
      if (e.requestType === 'searching') return this.searchInSyncfusionGrid(e)
    },
    searchInSyncfusionGrid(e) {
      if (this.searchBy === 'all') return
      this.projectData = this.persistedData.filter((item) => item[this.searchBy]?.toString().includes(e.searchString) ?? true)
    },
    selectDropdownHandler(opt) {
      this.searchBy = opt.value
    },
    setSearchOptions() {
      const searchWrapper = this.$refs.grid.$el.querySelector('.e-toolbar-right')
      const dropdown = this.$refs.selectOption.$el
      searchWrapper.prepend(dropdown)

      searchWrapper.style.display = 'flex'
      searchWrapper.style.gap = '1em'
      dropdown.querySelector('button').style.border = 'none'
    },
    checkSearchState() {
      const { labNo, board, page } = this.$store.state.searchStateStore

      if (board !== 'project') {
        this.$store.dispatch('searchStateStore/initailize')
        return false
      }

      if (this.labNo) return false

      if (!labNo) return false

      this.labNo = labNo
      this.$router.replace(`/admin/board/project/${labNo}`)
    }
  },
  async mounted() {
    // 프로젝트 그리드
    this.pjtGrid = this.$refs.grid.ej2Instances
    // 파라미터 프로젝트 그리드 페이지 번호
    const page = Number(this.$route.params.page)

    this.isLoading = true

    this.checkSearchState()

    // 프로젝트 전체 데이터 가져오기
    await this.loadPrjData()

    // 페이지 번호가 있을 때(프로젝트 보기 -> 목록으로 돌아온 경우) 받아온 페이지 번호로 SET
    if (page) {
      this.pjtGrid.pagerModule.pageSettings.currentPage = page
    } else {
      this.pjtGrid.pagerModule.pageSettings.currentPage = 1
    }

    // INFO: 검색 기능
    this.setSearchOptions()
    const getList = async () => {
      this.projectData = this.projectData.map((item) => {
        return {
          projectId: item.projectId,
          labNo: item.labNo,
          labName: item.labName,
          projectNumber: item.projectNumber,
          projectManager: item.projectManager,
          projectTitleKr: item.projectTitleKr,
          projectStatus: item.projectStatus
        }
      })

      this.persistedData = this.projectData
    }
    getList()

    this.isLoading = false
  }
}
</script>

<template>
  <LoadingVue v-if="isLoading"></LoadingVue>
  <div class="">
    <!-- 등록 버튼 -->
    <AddBtn @click="goProjectCreate()"></AddBtn>

    <!-- 검색기능 -->
    <Dropdown
      class="select-wrapper"
      :options="searchConditions"
      :disabled="false"
      name="zipcode"
      ref="selectOption"
      @selected="selectDropdownHandler"
    ></Dropdown>

    <!-- 프로젝트 게시판 그리드 -->
    <div class="">
      <ejs-grid
        ref="grid"
        :dataSource="projectData"
        :toolbar="toolbar"
        :allowPaging="true"
        :pageSettings="pageSettings"
        :allowSorting="true"
        :allowSelection="true"
        :rowSelected="projectRowSelected"
        :commandClick="commandClick"
        :actionComplete="actionComplete"
      >
        <e-columns>
          <e-column field="projectId" headerText="번호" width="80" textAlign="center" :isPrimaryKey="true"></e-column>
          <e-column field="labNo" headerText="연구실번호" width="100" textAlign="left"></e-column>
          <e-column field="labName" headerText="연구실이름" width="150" textAlign="left"></e-column>
          <e-column field="projectNumber" headerText="과제고유번호" width="120" textAlign="left"></e-column>
          <e-column field="projectManager" headerText="책임연구자" width="100" textAlign="left"></e-column>
          <e-column field="projectTitleKr" headerText="과제명" width="500" textAlign="left"></e-column>
          <e-column field="projectStatus" headerText="진행상태" width="100" textAlign="left"></e-column>
          <e-column field="manage" headerText="관리" width="100" textAlign="center" :commands="commands" />
        </e-columns>
      </ejs-grid>
    </div>
  </div>
</template>

<style scoped>
.btnArea {
  height: 50px;
}
.btnArea > button {
  float: right;
}
</style>
