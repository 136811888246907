//INFO: 관리자 화면의 등록 버튼
<script>
export default {
  name: '',
  components: {},
  data() {
    return {
      example: ''
    }
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {}
}
</script>

<template>
  <div class="wrapper">
    <label class="btn-wrapper">
      <button type="button" class="e-btn wf__btn btn-primary" variant="primary">등록</button>
    </label>
  </div>
</template>

<style scoped>
.wrapper {
  max-width: max-content;
  margin-bottom: 10px;
}
.btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>
