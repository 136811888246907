//INFO: 화면설명
<script>
export default {
  name: '',
  components: {},
  data() {
    return {
      example: ''
    }
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {}
}
</script>

<template>
  <section>
    <div class="spinner"></div>
  </section>
</template>

<style scoped>
section {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;
}
.spinner {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 8px solid #e0e0e0;
  border-bottom: 8px solid var(--wf-primary-color);
  animation: spin 1.5s linear infinite;
  position: relative;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
