import { useAxios } from '../../axiosUtil'
import { getLabPaper, getLabPatent } from '../../platform/community'
import { searchPaperAll, searchPatentAll } from './ntis'
import { patentDelete } from './ntisPatentAPI'

const token = localStorage.getItem('token')
const tokenType = localStorage.getItem('tokenType')
const headerOpt = {
  method: 'DELETE',
  headers: {
    Authorization: `${tokenType} ${token}`
  }
}

// const patentUrl = '/v1/board/lab-patent' // 지재권 url

const deletedRelatedData = async (array, getApi, delApi) => {
  if (!(array instanceof Array)) return

  const resultArray = []
  for (const item of array) {
    const result = await getApi(item)
    resultArray.push(await delApi(result.items instanceof Array ? result.items[0] : 0))
  }

  return resultArray
}
/**
 * 프로젝트 삭제 함수
 * @param {} boardNo 파라미터로 받는 게시글 번호
 * @param {*} pjtData 프로젝트 데이터
 */
export const deletePjtTable = async (boardNo, pjtData) => {
  let pjtUrl = '/v1/board/lab-project' // 프로젝트 url

  const pjtDataOpt = { data: pjtData, ...headerOpt }

  pjtUrl = `${pjtUrl}/${boardNo}`

  const result = await useAxios(pjtUrl, pjtDataOpt)
  if (result?.code) return result

  const projectNumber = pjtData.projectNumber

  const papers = await searchPaperAll({ SRWR: projectNumber })
  const patents = await searchPatentAll({ SRWR: projectNumber })

  const delPaper = await deletedRelatedData(
    papers.LIST,
    (paper) => getLabPaper({ paperResultID: paper.paperResultID, deletedFlag: 0 }),
    (paper) => deletePaperTable(paper.paperId)
  )
  console.log(delPaper)

  const delPatent = await deletedRelatedData(
    patents.LIST,
    (patent) => getLabPatent({ patentResultID: patent.patentResultID, deletedFlag: 0 }),
    (patent) => patentDelete(patent.patentId)
  )
  console.log(delPatent)
  return result
}

/**
 * 논문 삭제 함수
 * @param {} boardNo 파라미터로 받는 게시글 번호
 * @param {*} paperData 프로젝트 데이터
 */
export const deletePaperTable = async (boardNo, paperData) => {
  let paperUrl = '/v1/board/lab-paper' // 논문 url

  const paperDataOpt = { data: paperData, ...headerOpt }

  paperUrl = `${paperUrl}/${boardNo}`

  return await useAxios(paperUrl, paperDataOpt)
}

export async function deleteHandover(id) {
  const url = `/v1/board/lab-tech-handover/${id}`
  return useAxios(url, headerOpt)
}
